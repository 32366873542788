import type { IBodyElement } from './PermissionGroups';
import { useState, useEffect } from 'react';
import SortIcon from '../../assets/react-icons/SortIcon';
import { FontIcon } from 'react-md';
import Arrow from '../../assets/react-icons/Arrow';
import { orderBy } from 'lodash';

interface IListData {
  [key: string]: string | number | null
}

type TListData = keyof IListData;
type TSortDirection = 'asc' | 'desc';

interface IProps {
  body: IBodyElement[];
  head: { id: string, label: string, sortable: boolean }[];
}

function GroupsList({ body, head }: IProps) {
  const [sortColumn, setSortColumn] = useState<TListData>('name');
  const [sortDirection, setSortDirection] = useState<TSortDirection>('asc');
  const [data, setData] = useState(body);

  const sort = (list: any, key: TListData, direction: TSortDirection) => {
    return orderBy(list, `${key}.sortValue`, [direction]);
  };

  useEffect(() => {
    setData(sort(body, sortColumn, sortDirection));
  }, [body, sortColumn, sortDirection]);

  const setSortOptions = (key: TListData, direction: TSortDirection) => {
    setData(sort(body, key, direction));
    setSortColumn(key);
    setSortDirection(direction);
  };

  return (
    <>
      <div className="groups-list-headers">
        {head.map(({ id, label, sortable }) => {
          const currentItem = sortColumn === id;
          const direction = currentItem ? sortDirection : '';
        
          return (
            <div key={id} className={`groups-header-${id}`}>
              <button
                aria-label={`Sort table by ${label.toLowerCase()} in ${direction === 'asc' ? 'descending' : 'ascending'} order`}
                className="table__button--sort"
                disabled={!sortable}
                type="button"
                onClick={() => setSortOptions(id, direction === 'asc' ? 'desc' : 'asc')}
              >
                <span>{label}</span>
                {sortable && <SortIcon direction={direction} />}
              </button>
            </div>
          );
        })}
        <div className="groups-header-action">
          <FontIcon>more_vert</FontIcon>
        </div>
      </div>
      {data.map(({ name, users_in_group, app_permissions, externalKey }) => {
        return (
          <ul key={externalKey}>
            <li className='group-name'>{name.displayValue}</li>
            <li className='group-users_in_group'>{users_in_group.displayValue}</li>
            <li className='group-app_permissions'>{app_permissions.displayValue}</li>
            <li className="group-action">
              <Arrow />
            </li>
          </ul>
        );
      })}
    </>
  );
}

export default GroupsList;
