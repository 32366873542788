import ListLoading from '../ListLoading/ListLoading';
import { LinearProgress } from 'react-md';
import { PermissionGroupQuery_permissionGroup } from '../../../types/PermissionGroupQuery';
import { useGlobalContext } from '../GlobalContext/GlobalContext';
import PermissionActionBar from '../ActionBar/PermissionActionBar';
import GroupsList from './GroupsList';
import './PermissionGroups.scss';
import { isNotEmpty } from '../../utils';

interface IBodyElementChild {
  displayValue: string | number;
  sortValue: string | number;
}

export interface IBodyElement {
  name: IBodyElementChild;
  users_in_group: IBodyElementChild;
  app_permissions: IBodyElementChild;
  externalKey: string;
}

function PermissionGroups() {
  const { permissionGroupData, permissionGroupError, permissionGroupLoading } = useGlobalContext();
  const body: IBodyElement[] = permissionGroupData?.permissionGroup?.map((group: PermissionGroupQuery_permissionGroup) => ({
    name: {
      displayValue: group.displayName || '-',
      sortValue: group?.displayName?.toLowerCase() || ''
    },
    users_in_group: {
      displayValue: group.persons?.length || '0',
      sortValue: group.persons?.length || 0
    },
    app_permissions: {
      displayValue: 'Edit',
      sortValue: 'Edit'
    },
    externalKey: group.externalKey
  })).sort((a: IBodyElement, b: IBodyElement) => a.name.sortValue > b.name.sortValue ? 1 : -1);

  const head = [{
    id: 'name',
    label: 'Name',
    sortable: true
  }, {
    id: 'users_in_group',
    label: 'Users in group',
    sortable: true
  }, {
    id: 'app_permissions',
    label: 'App permissions',
    sortable: true
  }];

  return (
    <>
      <div className="progress-container">
        {permissionGroupLoading && <LinearProgress id="persons-linear-progress" />}
      </div>
      <section className="wrapper permissions-container">
        <PermissionActionBar />
        {permissionGroupError && <div>Error...</div>}
        {permissionGroupLoading && <ListLoading />}
        {isNotEmpty(permissionGroupData?.permissionGroup) && (
          <GroupsList body={body} head={head} />
        )}
      </section>
    </>
  );
};

export default PermissionGroups;
