import { useState } from 'react';
import { PersonsQuery_persons } from '../../../types/PersonsQuery';
import { LinearProgress } from 'react-md';
import { useGlobalContext } from '../GlobalContext/GlobalContext';
import ListLoading from '../ListLoading/ListLoading';
import UsersList from './UsersList';
import ActionBar from '../ActionBar/UsersActionBar';
import { isNotEmpty } from '../../utils';
import './Users.scss';

export interface IBodyElementChild {
  displayValue: string | number;
  sortValue: string | number;
}

export interface IBodyElement {
  name: IBodyElementChild;
  email: IBodyElementChild;
  user_group: IBodyElementChild;
  status: IBodyElementChild;
  externalKey: string;
}

function Users() {
  const { personsData, personsError, personsLoading } = useGlobalContext();
  const [selected, setSelected] = useState<string[]>([]);

  const body: IBodyElement[] = personsData?.persons.map((person: PersonsQuery_persons) => ({
    name: {
      displayValue: person.displayName || '-',
      sortValue: person?.displayName?.toLowerCase() || ''
    },
    email: {
      displayValue: person.email || '-',
      sortValue: person.email || 0
    },
    user_group: {
      displayValue: person?.permissionGroups![0]?.displayName || '-',
      sortValue: person?.permissionGroups![0]?.displayName || '-'
    },
    status: {
      displayValue: person?.status?.replace('_', ' ').toLowerCase() || '-',
      sortValue: person.status || ''
    },
    externalKey: person.externalKey
  })).sort((a: IBodyElement, b: IBodyElement) => a.name.sortValue > b.name.sortValue ? 1 : -1);

  const head = [{
    id: 'name',
    label: 'Name',
    sortable: true
  }, {
    id: 'email',
    label: 'Email',
    sortable: true
  }, {
    id: 'user_group',
    label: 'User Group',
    sortable: true
  }, {
    id: 'status',
    label: 'Status',
    sortable: true
  }];

  return (
    <>
      <div className="progress-container">
        {personsLoading && <LinearProgress id="persons-linear-progress" />}
      </div>
      <section className="wrapper persons-container">
        <ActionBar />
        {personsError && <div>Error...</div>}
        {personsLoading && <ListLoading />}
        {isNotEmpty(personsData?.persons) && (
          <UsersList
            body={body}
            head={head}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </section>
    </>
  );
}

export default Users;
