import { Button } from 'react-md';
import { SearchSVGIcon } from 'react-md';
import './ActionBar.scss';

function UsersActionBar() {
  return (
    <div className="action-bar-container">
      <div className="search-input-container">
        <input
          placeholder="Search by Keyword"
          type="text"
          disabled
        />
        <span>
          <SearchSVGIcon />
        </span>
      </div>
      <Button
        aria-label="placeholder"
        className="create-user"
        theme="secondary"
        themeType="contained"
        disabled
      >
        create a new user
      </Button>
    </div>
  );
}

export default UsersActionBar;
