import { HTMLAttributes } from 'react';

export interface SVGIconProps extends HTMLAttributes<SVGSVGElement> {
  direction: string;
}

const SortIcon = (props: SVGIconProps) => (
  <svg
    {...props}
    height="20"
    width="14"
    fill="none"
    viewBox="0 0 14 20"
  >
    <>
      <path
        d="M10 13L7 16L4 13"
        stroke="black"
        strokeOpacity={props.direction === 'asc' ? 0.1 : props.direction === 'desc' ? 0.5 : 0.1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 7L7 4L10 7"
        stroke="black"
        strokeOpacity={props.direction === 'asc' ? 0.5 : props.direction === 'desc' ? 0.1 : 0.1}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  </svg>
);

export default SortIcon;
