import _ from 'lodash';

export * from './authentication';

export function isNotEmpty(element: any): boolean {
  return !_.isEmpty(element);
}

export const FUSE_OPTIONS = {
  findAllMatches: false,
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  includeScore: false,
  minMatchCharLength: 1
};
