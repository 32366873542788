import './ListLoading.scss';

const ListLoading = () => (
  <div className="chart-card loading">
    <div className="loading-header" style={{ opacity: .5 }} />
    <div className="loading-list" />
    <div className="loading-list" />
    <div className="loading-list" style={{ opacity: .75 }} />
    <div className="loading-list" style={{ opacity: .75 }} />
    <div className="loading-list" style={{ opacity: .5 }} />
    <div className="loading-list" style={{ opacity: .5 }} />
    <div className="loading-list" style={{ opacity: .25 }} />
    <div className="loading-list" style={{ opacity: .25 }} />
  </div>
);

export default ListLoading;
