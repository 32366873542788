import { NavLink } from 'react-router-dom';
import './TabNavigation.scss';

function TabNavigation() {
  return (
    <nav className="tab-navigation">
      <div className="wrapper">
        <NavLink
          className={({ isActive }) => (( isActive ? 'nav-link active-0 active' : 'nav-link'))}
          to="/users"
        >
          Users
          <div className="bottom-border"/>
        </NavLink>

        <NavLink
          className={({ isActive }) => (( isActive ? 'nav-link active-1 active' : 'nav-link'))}
          to="/permission-groups"
        >
          Permission Groups
          <div className="bottom-border"/>
        </NavLink>
      </div>
    </nav>
  );
}

export default TabNavigation;
