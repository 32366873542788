import type { IBodyElement } from './Users';
import { useState, useEffect } from 'react';
import { FontIcon, Checkbox } from 'react-md';
import SortIcon from '../../assets/react-icons/SortIcon';
import cx from 'classnames';
import Arrow from '../../assets/react-icons/Arrow';
import { orderBy } from 'lodash';

interface IListData {
  [key: string]: string | number | null
}

type TListData = keyof IListData;
type TSortDirection = 'asc' | 'desc';

interface IProps {
  body: IBodyElement[];
  head: { id: string, label: string, sortable: boolean }[];
  selected: Array<string>;
  setSelected: Function;
}

function UsersList({ body, head, selected, setSelected }: IProps) {
  const [sortColumn, setSortColumn] = useState<TListData>('name');
  const [sortDirection, setSortDirection] = useState<TSortDirection>('asc');
  const [data, setData] = useState(body);

  const sort = (list: any, key: TListData, direction: TSortDirection) => orderBy(list, `${key}.sortValue`, [direction]);
  
  useEffect(() => {
    setData(sort(body, sortColumn, sortDirection));
  }, [body, sortColumn, sortDirection]);

  const handleChange = (value: string, rowSelected: boolean) => {
    if (rowSelected) {
      return setSelected(selected.filter((selection) => selection !== value));
    }

    setSelected([...selected, value]);
  };

  const handleSelectAll = () => {
    if (selected.length === body.length) {
      return setSelected([]);
    }

    setSelected([...body.map((person) => person.status.displayValue !== 'deactivated' && person.externalKey)]);
  };

  const setSortOptions = (key: TListData, direction: TSortDirection) => {
    setData(sort(body, key, direction));
    setSortColumn(key);
    setSortDirection(direction);
  };

  return (
    <>
      <div className="user-list-headers">
        <div className="users-header-select-all">
          <Checkbox
            checked={selected.length === body.length}
            id="users-select-all"
            onChange={() => handleSelectAll()}
          />
        </div>
        {head.map(({ id, label, sortable }) => {
          const currentItem = sortColumn === id;
          const direction = currentItem ? sortDirection : '';

          return (
            <div key={id} className={`users-header-${id}`}>
              <button
                aria-label={`Sort table by ${label.toLowerCase()} in ${direction === 'asc' ? 'descending' : 'ascending'} order`}
                className="table__button--sort"
                disabled={!sortable}
                type="button"
                onClick={() => setSortOptions(id, direction === 'asc' ? 'desc' : 'asc')}
              >
                <span>{label}</span>
                {sortable && <SortIcon direction={direction} />}
              </button>
            </div>
          );
        })}
        <div className="user-header-action">
          <FontIcon>more_vert</FontIcon>
        </div>
      </div>
      {data.map(({ name, email, user_group, status, externalKey }) => {
        const deactivated = status.displayValue === 'deactivated';
        const rowSelected = selected.includes(externalKey);

        return (
          <ul
            key={externalKey}
            className={cx(deactivated && 'deactivated', 'list-item-row')}
          >
            <li className="user-checkbox">
              <Checkbox
                key={externalKey}
                checked={selected.includes(externalKey)}
                disabled={deactivated}
                id={externalKey}
                onChange={() => handleChange(externalKey, rowSelected)}
              />
            </li>
            <li className="user-display-name">{name.displayValue}</li>
            <li className="user-email">{email.displayValue}</li>
            <li className="user-permission-group">{user_group.displayValue}</li>
            <li className="user-status">{status.displayValue}</li>
            <li className="user-action">
              <Arrow />
            </li>
          </ul>
        );
      })}
    </>
  );
}

export default UsersList;
