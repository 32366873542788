import ParkhubLogo from '../../assets/parkhub-logo.svg';
import { Header } from '@parkhub/parkhub-ui';
import { Navigate, Routes, Route } from 'react-router-dom';
import { signOut, createFeatureAccess } from '../../utils';
import Users from '../Users/Users';
import PermissionGroups from '../PermissionGroups/PermissionGroups';
import TabNavigation from '../../TabNavigation/TabNavigation';
import { IAuthUser } from '../../../types/types';

interface ILayoutProps {
  authUser: IAuthUser;
}

function Layout({ authUser }: ILayoutProps) {
  const access = createFeatureAccess();

  return (
    <>
      <Header
        access={access}
        authUser={authUser}
        logoSrc={ParkhubLogo}
        logoWidth={152}
        signOut={() => signOut()}
        appMenuActive
      >
        <TabNavigation />
      </Header>
      <main>
        <Routes>
          <Route
            element={
              <Navigate to="/users" />
            }
            path="/"
          />
          <Route
            element={
              <Users />
            }
            path="/users"
          />
          <Route
            element={
              <PermissionGroups />
            }
            path="/permission-groups"
          />
        </Routes>
      </main>
    </>
  );
}

export default Layout;
