import { gql } from '@apollo/client';

export const PERSONS_QUERY = gql`
  query PersonsQuery(
    $activeOnly: Boolean,
    $offset: Int,
    $limit: Int,
    $status: String,
    $permissionGroupExternalKey: ID
  ) {
    persons(
      activeOnly: $activeOnly
      offset: $offset
      limit: $limit
      status: $status
      permissionGroupExternalKey: $permissionGroupExternalKey
    ) {
      externalKey
      activation
      created
      deactivation
      displayName
      email
      firstName
      lastName
      updated
      status
      permissionGroups {
          externalKey
          displayName
          searchName
          organizationKey
      }
    }
  }
`;
