import { ReactNode, createContext, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { PERSONS_QUERY } from '../../graphql/persons.query';
import { PERMISSION_GROUP_QUERY } from '../../graphql/permissionGroup.query';
import { IFilterOptions } from '../../../types/types';
import { PersonsQuery, PersonsQueryVariables } from '../../../types/PersonsQuery';
import { PermissionGroupQuery, PermissionGroupQueryVariables } from '../../../types/PermissionGroupQuery';


interface IProps {
  children: ReactNode;
};

interface IGlobalContext {
  filterOptions: IFilterOptions;
  setFilterOptions: () => {};
  selectedFilters: PersonsQueryVariables;
  setSelectedFilters: () => {};
}

const GlobalContext = createContext<IGlobalContext | any>({});

export function GlobalContextProvider({ children }: IProps) {
  const [personFilters, setPersonFilters] = useState<PersonsQueryVariables>({
    activeOnly: null,
    offset: null,
    limit: 500,
    status: null,
    permissionGroupExternalKey: null
  });

  const [permissionGroupFilters, setPermissionGroupFilters] = useState<PermissionGroupQueryVariables>({
    externalKey: null,
    searchName: null,
    organizationKey: null
  });

  const {
    refetch: personsRefetch,
    loading: personsLoading,
    error: personsError,
    data: personsData
  } = useQuery<PersonsQuery, PersonsQueryVariables>(PERSONS_QUERY, {
    variables: personFilters
  });

  const {
    refetch: permissionGroupRefetch,
    loading: permissionGroupLoading,
    error: permissionGroupError,
    data: permissionGroupData
  } = useQuery<PermissionGroupQuery, PermissionGroupQueryVariables>(PERMISSION_GROUP_QUERY, {
    variables: permissionGroupFilters
  });

  function changeFilterOptions(options : PersonsQueryVariables) {
    return setPersonFilters(options);
  }

  return (
    <GlobalContext.Provider
      value={{
        personsData,
        personsError,
        personsLoading,
        personsRefetch,
        personFilters,
        changeFilterOptions,
        permissionGroupData,
        permissionGroupError,
        permissionGroupLoading,
        permissionGroupRefetch,
        setPermissionGroupFilters
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export const useGlobalContext = () => useContext(GlobalContext);
