import { HTMLAttributes } from 'react';

export interface SVGIconProps extends HTMLAttributes<SVGSVGElement> {
  className?: string;
}

const Arrow = (props: SVGIconProps) => (
  <svg
    {...props}
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
  >
    <path d="M1.5 1.5L6.5 7L1.5 12.5" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

export default Arrow;
